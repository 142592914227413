import React from "react";
import {IJobOffer} from "../../types/jobOffer.interface";
import JobOfferItem from "./items/JobOfferItem";
import styles from "./JobOffer.module.css";

interface Props {
    offer: IJobOffer;
}
const JobOffer: React.FC<Props> = ({offer}) => {
    return (
        <div className={styles.wrapper}>
            {offer.items?.map(item => <JobOfferItem item={item}/>)}
        </div>
    )
}

export default JobOffer;