import React from "react";
import {IJobOfferItem} from "../../../../types/jobOffer.interface";
import styles from "./JobOfferTitle.module.css";

interface Props {
    item: IJobOfferItem;
}
const JobOfferTitle: React.FC<Props> = ({item}) => {
    return (
        <div className={styles.title}>{item?.text}</div>
    )
}

export default JobOfferTitle;