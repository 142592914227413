import React from "react";
import {JobOfferItemType} from "../../../enums/jobOfferItemType.enum";
import {IJobOfferItem} from "../../../types/jobOffer.interface";
import JobOfferTitle from "./JobOfferTitle/JobOfferTitle";
import JobOfferText from "./JobOfferText/JobOfferText";
import JobOfferList from "./JobOfferList/JobOfferList";
import JobOfferEvaluation from "./JobOfferEvaluation/JobOfferEvaluation";
import JobOfferListItem from "./JobOfferListItem/JobOfferListItem";

interface Props {
    item: IJobOfferItem;
}
const JobOfferItem: React.FC<Props> = ({item}) => {
    switch (item.type){
        case JobOfferItemType.TITLE:
            return <JobOfferTitle item={item}/>
        case JobOfferItemType.TEXT:
            return <JobOfferText item={item}/>
        case JobOfferItemType.LIST:
            return <JobOfferList item={item}/>
        case JobOfferItemType.EVALUATION:
            return <JobOfferEvaluation item={item}/>
        case JobOfferItemType.LIST_ITEM:
            return <JobOfferListItem item={item}/>
        case JobOfferItemType.SPACE:
            return <div style={{height: "20px"}}/>
    }
    return <></>
}

export default JobOfferItem;