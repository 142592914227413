import { Country } from "../enums/country.enum"
import {ICompanyClaim} from "../components/CompanyClaim/CompanyClaim";

export const COMPANY_CLAIM: Record<string, ICompanyClaim> = {
    [Country.CZECHIA]: {
        logoSubtitle: 'Ordinace bez hranic',
        text: 'MEDDI hub je nadnárodní společnost, která již od roku 2016 vyvíjí telemedicínská řešení, CDM, MCM systémy, pacientské portály a integrační řešení softwaru bez ohledu na datový standard. Přeshraniční komunikace MEDDI. Cíl je přinést zdravotnictví 21. století.'
    },
    [Country.SLOVENIA]: {
        logoSubtitle: 'Ordinacija brez meja',
        text: 'MEDDI hub je multinacionalno podjetje, ki že od leta 2016. razvija telemedicinske rešitve, sisteme CDM, MCM, portale za bolnike in rešitve za integracijo programske opreme ne glede na podatkovni standard. MEDDI čezmejna komunikacija. Cilj je pripeljati zdravstvo v 21. stoletje.'
    },
    [Country.SERBIA]: {
        logoSubtitle: 'Lekarska ordinacija bez granica',
        text: 'MEDDI hub je multinacionalna kompanija koja od 2016. razvija telemedicinska rešenja, CDM, MCM sisteme, portale za pacijente i rešenja za integraciju softvera bez obzira na standard podataka. MEDDI prekogranična komunikacija. Cilj je dovesti zdravstvo u 21. vek.'
    }
}