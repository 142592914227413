import React from "react";
import styles from "../styles/Homepage.module.css";
import CompanyClaim from "../components/CompanyClaim/CompanyClaim";
import {JOB_OFFERS} from "../assets/jobOffers";
import CountryCard from "../components/CountryCard/CountryCard";
import {Country} from "../enums/country.enum";
const Homepage: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <div className='container mx-auto'>
                <CompanyClaim logoSubtitle={'Borderless Medicine'}/>
                <div className={styles.countryCards}>
                    {Object.keys(JOB_OFFERS).map((item) => {
                        return <CountryCard country={item as Country}/>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Homepage;