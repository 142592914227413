import React from "react";
import {IJobOfferItem} from "../../../../types/jobOffer.interface";
import styles from "./JobOfferListItem.module.css";
import NoEscape from "../../../Base/NoEscape/NoEscape";

interface Props {
    item: IJobOfferItem;
}
const JobOfferListItem: React.FC<Props> = ({item}) => {
    return (
        <div className={styles.item}>
            <div className={styles.check}/>
            {item?.text && (<div className={styles.title}><NoEscape>{item.text}</NoEscape></div>)}
        </div>
    )
}

export default JobOfferListItem;