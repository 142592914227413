import React from "react";
import styles from './CompanyClaim.module.css';

export interface ICompanyClaim {
    logoSubtitle: string;
    text?: string;
}

const CompanyClaim: React.FC<ICompanyClaim> = ({logoSubtitle, text}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.logotype}>
                <img src='/images/logotype.svg'/>
                <div className={styles.logoText}>
                    <div><span>MEDDI</span></div>
                    <div>{logoSubtitle}</div>
                </div>
            </div>
            {text && <div className={styles.text}>{text}</div>}
        </div>
    )
}

export default CompanyClaim;