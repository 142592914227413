import React from "react";
import {IJobOfferItem} from "../../../../types/jobOffer.interface";
import styles from "./JobOfferText.module.css";
import NoEscape from "../../../Base/NoEscape/NoEscape";

interface Props {
    item: IJobOfferItem;
}

const JobOfferText: React.FC<Props> = ({item}) => {
    if (item?.text) {
        return (
            <div className={styles.text}><NoEscape>{item.text}</NoEscape></div>
        )
    }
    return <></>
}

export default JobOfferText;