import React from "react";
import {IJobOfferItem} from "../../../../types/jobOffer.interface";
import JobOfferItem from "../JobOfferItem";
import styles from "./JobOfferList.module.css";

interface Props {
    item: IJobOfferItem;
}
const JobOfferList: React.FC<Props> = ({item}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>{item?.text}</div>
            {item?.children?.map(child => <JobOfferItem item={child}/>)}
        </div>
    )
}

export default JobOfferList;