import React from "react";
import {IJobOfferItem} from "../../../../types/jobOffer.interface";
import styles from "./JobOfferEvaluation.module.css";

interface Props {
    item: IJobOfferItem;
}
const JobOfferEvaluation: React.FC<Props> = ({item}) => {
    return (
        <div className={styles.item}>{item?.text}</div>
    )
}

export default JobOfferEvaluation;