import React from "react";
import {Country} from "../../enums/country.enum";
import styles from './CountryCard.module.css';

interface ICountryCard {
    country: Country;
}

const COUNTRIES: Record<Country, { label: string; flag: string; }> = {
    [Country.CZECHIA]: {
        label: 'Česká republika',
        flag: 'https://cdn.meddi.com/flags/svg/cs_CZ.svg'
    },
    [Country.SLOVENIA]: {
        label: 'Slovenija',
        flag: 'https://cdn.meddi.com/flags/svg/sl_SL.svg'
    },
    [Country.SERBIA]: {
        label: 'Srbija',
        flag: 'https://cdn.meddi.com/flags/svg/sr_SR.svg'
    },
}

const CountryCard: React.FC<ICountryCard> = ({country}) => {
    return (
        <a href={`/${country}`} className={styles.wrapper}>
            <img src={COUNTRIES[country].flag}/>
            <div className={styles.title}>{COUNTRIES[country].label}</div>
        </a>
    )
}

export default CountryCard;