import React, {useMemo} from "react";
import {useParams} from "react-router-dom";
import {IJobOffer} from "../types/jobOffer.interface";
import JobOffer from "../components/JobOffer/JobOffer";
import styles from '../styles/OpenPositions.module.css';
import {COMPANY_CLAIM} from "../assets/companyClaim";
import CompanyClaim from "../components/CompanyClaim/CompanyClaim";
import {JOB_OFFERS} from "../assets/jobOffers";

const OpenPositions: React.FC = () => {
    const {country} = useParams();

    const offers: IJobOffer[] | [] = useMemo(() => {
        if (country) {
            return JOB_OFFERS[country];
        }
        return [];
    }, [country]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.arc}/>
            <div className='container mx-auto'>
                {country && (
                    <CompanyClaim logoSubtitle={COMPANY_CLAIM[country].logoSubtitle}
                                  text={COMPANY_CLAIM[country].text}/>
                )}
                <div className='grid grid-cols-1'>
                    {offers.map((item, key) => <React.Fragment key={key}><JobOffer offer={item}/></React.Fragment>)}
                </div>
            </div>
        </div>
    )
}

export default OpenPositions;