import {IJobOffer} from "../types/jobOffer.interface";
import {Country} from "../enums/country.enum";
import {JobOfferItemType} from "../enums/jobOfferItemType.enum";

export const JOB_OFFERS: Record<string, IJobOffer[]> = {
    [Country.CZECHIA]: [
        {
            title: "Lékař česko",
            items: [
                {
                    type: JobOfferItemType.TITLE,
                    text: 'Lékař online - MEDDI ambulance'
                },
                {
                    type: JobOfferItemType.EVALUATION,
                    text: 'ODMĚNA od 100 tis. Kč'
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'Staňte se součástí úspěšného medicínského projektu – začněte ordinovat bez hranic, zapojte telemedicínu do své praxe a usnadněte tak život sobě i pacientům.'
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'MEDDI hub je nejúspěšnější telemedicínskou společností na území ČR a SR, která momentálně  rozšiřuje své působení na evropský, ale i celosvětový trh. Do činnosti MEDDI hub se již zapojilo více než 4600 lékařů napříč všemi specializacemi.'
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'Spolupracujeme se světově známými společnostmi, jako je například Veolia, Visa či Notino, a jsme oficiálními partnery předních medicínských institucí v ČR. Ve spolupráci s 1. lékařskou fakultou Univerzity Karlovy se navíc podílíme na výuce využití telemedicíny v praxi, za pomocí našich MEDDI řešení.'
                },
                {
                    type: JobOfferItemType.LIST,
                    text: 'Přidejte se k nám, pokud:',
                    children: [
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'chcete dělat svou práci smysluplně a nebýt limitován/a zdmi své ordinace'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'hledáte způsob, jak zefektivnit a zlepšit péči o pacienty'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'umíte pracovat samostatně, a zároveň chcete být součástí týmu špičkových lékařů'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'baví Vás práce s telefonem a PC'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'věříte, že telemedicína je budoucnost'
                        }
                    ]
                },
                {
                    type: JobOfferItemType.LIST,
                    text: 'Co Vám nabízíme?',
                    children: [
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'bezpečné zázemí mezinárodní společnosti specializované na online projekty v oblasti medicíny a zdravotnictví'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'skvělé pracovní ohodnocení'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'práci z domova – pohodlně, odkudkoliv z <span>ČR či ze zahraničí</span>'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'flexibilní pracovní dobu*'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'průběžná školení, workshopy a kurzy zaměřené na Váš seberozvoj'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'přátelskou, neformální atmosféru'
                        }
                    ]
                },
                {
                    type: JobOfferItemType.SPACE,
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: '*Pozice je vhodná i jako přivýdělek (DPP).'
                },
                {
                    type: JobOfferItemType.LIST,
                    text: 'Co bude Vaší pracovní náplní?',
                    children: [
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'péče o pacienty v online prostředí'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'komunikace s pacienty po telefonu a emailu'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'práce s počítačem a interními systémy'
                        }
                    ]
                },
                {
                    type: JobOfferItemType.LIST,
                    text: 'Co od Vás očekáváme?',
                    children: [
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'odborné znalosti'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'zdravotnickou praxi'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'lidský přístup a chuť pomáhat'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'uživatelskou znalost práce na PC a telefonu'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'komunikativnost, samostatnost, spolehlivost'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'pozitivní přístup a týmového ducha'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'časové možnosti (minimálně čtyři směny měsíčně, odkudkoliv, kde je kvalitní internetové připojení)'
                        }
                    ]
                },
                {
                    type: JobOfferItemType.SPACE,
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'Pro více informací o aplikaci a o MEDDI hub navštivte naše webové stránky: <a href="https://www.meddi.com" target="_blank">www.meddi.com</a>'
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'Máte-li zájem o tuto pozici, zašlete prosím Váš životopis na: <a href="mailto:hr@meddi.com">hr@meddi.com</a>'
                },
            ]
        }
    ],
    [Country.SLOVENIA]: [
        {
            title: "Zdravnik preko spleta – MEDDI ambulanta",
            items: [
                {
                    type: JobOfferItemType.TITLE,
                    text: 'Zdravnik preko spleta – MEDDI ambulanta'
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'Postanite del uspešnega, rastočega medicinskega projekta – začnite delati brez meja in dovolite telemedicini postati del vaše zdravstvene prakse ter dovolite, da olajša življenje vam in vašim pacientom.'
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'MEDDI hub je najuspešnejše telemedicinsko podjetje na Češkem in Slovaškem. Sedaj se širi na evropski, pa tudi svetovni trg. Več kot 4600 zdravnikov z vsemi vrstami specializacij je že postalo del MEDDI huba.'
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'Sodelujemo s svetovno znanimi blagovnimi znamkami, kot so Veolia, Visa, DHL, Hartmann ali Notino. Smo uradni partnerji vodilnih zdravstvenih ustanov Češke republike. Sodelujemo celo pri poučevanju uporabne telemedicine skupaj s Prvo medicinsko fakulteto Karlove univerze.'
                },
                {
                    type: JobOfferItemType.LIST,
                    text: 'Pridružite se nam, če:',
                    children: [
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'želite opravljati smiselno delo, ko vas ne omejujejo zidovi vaše pisarne'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'iščejte načine, kako izboljšati oskrbo bolnikov in jo narediti učinkovitejšo'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'lahko delate samostojno, medtem ko ste del ekipe najboljših zdravnikov'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'uživate v delu s telefonom in računalnikom'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'tako kot smo prepričani, da je telemedicina prihodnost'
                        }
                    ]
                },
                {
                    type: JobOfferItemType.LIST,
                    text: 'Kaj vam lahko ponudimo?',
                    children: [
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'varno okolje mednarodnega podjetja, specializiranega za projekte spletne medicine in zdravstva'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'odlično plačilo za vaše delo'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'delo od doma '
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'prilagodljiv delovni čas *'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'nenehne možnosti usposabljanja, delavnice, tečaje, osredotočene na vaš samorazvoj'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'prijazno in neformalno vzdušje'
                        }
                    ]
                },
                {
                    type: JobOfferItemType.SPACE,
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: '*Delo je primerno tudi kot sekundarna zaposlitev (kot izvajalec).'
                },
                {
                    type: JobOfferItemType.LIST,
                    text: 'Kakšno je vaše področje odgovornosti?',
                    children: [
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'skrbeli za bolnike v spletnem okolju'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'komunicirali s pacienti po telefonu in elektronski pošti'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'delali na računalniku, z internimi sistemi in IT orodji'
                        }
                    ]
                },
                {
                    type: JobOfferItemType.LIST,
                    text: 'Kaj pričakujemo od vas?',
                    children: [
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'zaključeno specialistično zdravniško usposabljanje'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'medicinske izkušnje'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'human pristop in pripravljenost pomagati drugim'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'uporabniško poznavanje dela z osebnim računalnikom in telefonom'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'komunikacijske sposobnosti, samozavest in zanesljivost'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'pozitiven odnos in timski duh'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'dovoljšno razpoložljivost v vašem urniku (vsaj štiri izmene na mesec)'
                        }
                    ]
                },
                {
                    type: JobOfferItemType.SPACE,
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'Za več informacij o aplikaciji in MEDDI hubu obiščite našo spletno stran <a href="https://www.meddi.com" target="_blank">www.meddi.com</a>'
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'Če vas delovno mesto zanima, nam pošljite življenjepis na <a href="mailto:hr@meddi.com">hr@meddi.com</a>'
                },
            ]
        }
    ],
    [Country.SERBIA]: [
        {
            title: "Lekar na mreži - MEDDI hitna pomoć",
            items: [
                {
                    type: JobOfferItemType.TITLE,
                    text: 'Lekar na mreži - MEDDI hitna pomoć'
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'Postanite deo uspešnog medicinskog projekta – počnite da radite bez granica i učinite telemedicinu delom svoje medicinske prakse, olakšavajući život vama i vašim pacijentima.'
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'MEDDI čvorište je najuspešnija telemedicinska kompanija u Češkoj i Slovačkoj. Sada širi svoje aktivnosti na evropsko, ali i svetsko tržište. Preko 4600 lekara sa svim vrstama specijalizacija već je postalo deo MEDDI centra.'
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'Radimo sa svetskim brendovima kao što su Veolia, Visa, DHL, Hartmann ili Notino. Zvanični smo partneri vodećih medicinskih ustanova Češke Republike. Čak učestvujemo u nastavi primenjene telemedicine zajedno sa Prvim medicinskim fakultetom Karlovog univerziteta.'
                },
                {
                    type: JobOfferItemType.LIST,
                    text: 'Pridružite nam se ako:',
                    children: [
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'želite da radite smislen posao, a da niste sputani zidovima vaše kancelarije'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'traže načine da poboljšaju negu pacijenata i učine je efikasnijom'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'možete da radite samostalno dok ste deo tima najboljih lekara u klasi'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'uživate u radu za telefonom i računarom'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'da li ste uvereni, kao i mi, da je telemedicina budućnost'
                        }
                    ]
                },
                {
                    type: JobOfferItemType.LIST,
                    text: 'Šta vam možemo ponuditi?',
                    children: [
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'bezbedno okruženje međunarodne kompanije specijalizovane za onlajn medicinu i projekte zdravstvene zaštite'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'odlična nadoknada za vaš rad'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'rad od kuće'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'fleksibilno radno vreme *'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'stalne mogućnosti obuke, radionice, kursevi fokusirani na vaš samorazvoj'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'prijateljska i neformalna atmosfera'
                        }
                    ]
                },
                {
                    type: JobOfferItemType.SPACE,
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: '*Pozicija je odgovarajuća i kao sekundarni posao (kao izvođač radova). šta ćeš raditi?'
                },
                {
                    type: JobOfferItemType.LIST,
                    text: 'Šta će vaš posao obuhvatati?',
                    children: [
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'briga o pacijentima u onlajn okruženju'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'komuniciranje sa pacijentima putem telefona i e-pošte'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'rad na računaru, sa internim sistemima i IT alatima'
                        }
                    ]
                },
                {
                    type: JobOfferItemType.LIST,
                    text: 'Šta očekujemo od vas?',
                    children: [
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'Završene specijalističke medicinske studije medicinsko iskustvo'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'human pristup i spremnost da se pomogne drugima'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'korisničko poznavanje rada sa računarom i na telefonu'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'komunikacijske veštine, samopouzdanje i pouzdanost'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'pozitivan stav i timski duh'
                        },
                        {
                            type: JobOfferItemType.LIST_ITEM,
                            text: 'dovoljno vremena na raspolaganju u vašem rasporedu (najmanje četiri smene mesečno) '
                        }
                    ]
                },
                {
                    type: JobOfferItemType.SPACE,
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'Za više informacija o aplikaciji i MEDDI čvorištu, posetite našu veb stranicu <a href="https://www.meddi.com" target="_blank">www.meddi.com</a>'
                },
                {
                    type: JobOfferItemType.TEXT,
                    text: 'Ukoliko ste zainteresovani za poziciju, pošaljite nam svoju biografiju na <a href="mailto:hr@meddi.com">hr@meddi.com</a>'
                },
            ]
        }
    ],
}